// ■コンポーネント説明
// 各ページ下部お問い合わせ表示用コンポーネント

import React, { useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import hoverCircle from '@/components/Interaction/hoverCircle';

import * as styles from '@/styles/module/contact-area.module.scss';

export interface ContactBoxProps {
  service?: readonly Pick<
    MicrocmsServiceBodyContact,
    'button' | 'title' | 'id' | 'tayori'
  >[];
}

const ContactBox: React.FC<ContactBoxProps> = (props) => {
  let contactFlag = false;
  useEffect(() => {
    hoverCircle();
  }, []);

  const { service } = props;
  const data = useStaticQuery<GatsbyTypes.ContactBoxQuery>(
    graphql`
      query ContactBox {
        allMicrocmsContact(sort: { fields: createdAt, order: ASC }) {
          edges {
            node {
              id
              contactId
              button
              tayori
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <aside className={styles.contact_area}>
        <div>
          <p className={styles.contact_area__text}>
            当社のサービス・製品に関するご相談やご質問、お見積りのご依頼など、こちらからお問い合わせください。
          </p>
          <div className={styles.contact_area__button_wrap}>
            <div className={styles.contact_area_button}>
              {service !== undefined &&
                data.allMicrocmsContact.edges.map(({ node }) => {
                  for (let i = 0; i < service.length; i += 1) {
                    if (service[i].id === node.contactId) {
                      contactFlag = true;
                      return (
                        <Link
                          key={service[i].id}
                          to={`/contact/${service[i].id}/`}
                          className="button-02 js-hover-circle"
                          data-circle-type="reverse-button"
                          data-circle-inner="arrow-right-orange"
                          style={{ color: `#fff` }}
                        >
                          {service[0].button}
                        </Link>
                      );
                    }
                  }
                })}
              {!contactFlag && (
                <Link
                  to="/contact/general/"
                  className="button-02 js-hover-circle"
                  data-circle-type="reverse-button"
                  data-circle-inner="arrow-right-orange"
                  style={{ color: `#fff` }}
                >
                  お問い合わせ
                </Link>
              )}
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default ContactBox;
