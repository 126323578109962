// ■コンポーネント説明
// AWSソリューションページでのAWS関連の事例、書籍表示用コンポーネント

import React, { useMemo } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import * as Util from '@/components/Util';
import CDNReplace from '../CDNReplace';
import UILabel from '../UI/UILabel';

const AwsRelation: React.FC<AwsRelationProps> = (props) => {
  let displayCasesArray = [];
  let displayBooksArray = [];
  let ShuffleFlag = false;

  const data = useStaticQuery<GatsbyTypes.AwsRelationQuery>(
    graphql`
      query AwsRelation {
        allMicrocmsCase(
          sort: { fields: publishedAt, order: DESC }
          filter: {
            tag: { elemMatch: { id: { eq: "aws" } } }
            caseId: { ne: "template" }
          }
        ) {
          edges {
            node {
              caseId
              category
              customer
              title
              thumbnail {
                url
              }
              tag {
                tag
                id
              }
              publishedAt
            }
          }
        }
        allMicrocmsBooks(
          filter: { tag: { elemMatch: { id: { eq: "aws" } } } }
        ) {
          edges {
            node {
              id
              booksId
              title
              content
              image {
                url
                height
                width
              }
              tag {
                tag
                id
              }
              issuedDate(formatString: "YYYY年MM月")
            }
          }
        }
        microcmsEnv {
          commonNoImage {
            url
          }
        }
      }
    `,
  );

  // 関連事例、書籍の取得
  data.allMicrocmsCase.edges.map(({ node }) => {
    displayCasesArray.push(node);
    return null;
  });
  data.allMicrocmsBooks.edges.map(({ node }) => {
    displayBooksArray.push(node);
    return null;
  });

  // ランダムに並び替え、2つまでになるよう調整
  const arrayShuffle = (array: string[]) => {
    const tempArray = array;
    for (let i = array.length - 1; i > 0; i -= 1) {
      const r = Math.floor(Math.random() * (i + 1));

      const tmp = tempArray[i];
      tempArray[i] = tempArray[r];
      tempArray[r] = tmp;
    }

    const shuffledArray = [];
    let sortCount = 0;

    if (tempArray.length <= 2) {
      sortCount = tempArray.length;
    } else {
      sortCount = 2;
    }

    for (let i = 0; i < sortCount; i += 1) {
      shuffledArray.push(tempArray[i]);
    }

    return shuffledArray;
  };

  if (!ShuffleFlag) {
    // 事例は2つ、書籍は1つに。
    displayCasesArray = useMemo(() => arrayShuffle(displayCasesArray), []);
    displayBooksArray = useMemo(
      () =>
        displayBooksArray[Math.floor(Math.random() * displayBooksArray.length)],
      [],
    );
    ShuffleFlag = true;
  }

  return (
    <div className="page-contents-feature">
      <h2 className="title-lv3">実績紹介</h2>
      <p className="text-01">
        AWS関連でNRIネットコムが手がけたお客さまとのプロジェクトや、書籍の一部を紹介します。
      </p>
      <section className="section">
        <div className="box-3rows-02 --tab-rows">
          <div className="box-3rows-02">
            {displayCasesArray.map(
              ({ caseId, thumbnail, tag, title, customer }) => (
                <Link
                  key={caseId}
                  to={`/cases/${caseId}/`}
                  className="box-3rows-02__item item-card-02 js-hover-circle"
                  data-circle-type=""
                  data-circle-inner="arrow-right"
                >
                  <figure className="item-card-02__fig">
                    <img
                      loading="lazy"
                      src={`${CDNReplace(
                        thumbnail
                          ? thumbnail.url
                          : data.microcmsEnv.commonNoImage.url,
                      )}?fit=crop&w=320&h=180&auto=format&q=75`}
                      alt=""
                    />
                  </figure>
                  <div className="item-card-02__main">
                    <div className="item-card-02__tag">
                      {tag.map(({ tag, id }) => (
                        <UILabel key={id} text={tag} />
                      ))}
                    </div>
                    <h4 className="item-card-02__title">{title}</h4>
                    <p className="item-card-02__text">{customer}</p>
                  </div>
                </Link>
              ),
            )}
            <Link
              to={`/books/${displayBooksArray.booksId}/`}
              className="box-3rows-02__item item-card-02 js-hover-circle"
              data-circle-type=""
              data-circle-inner="arrow-right"
            >
              <figure className="item-card-02__fig">
                <img
                  loading="lazy"
                  src={`${CDNReplace(
                    displayBooksArray.image.url,
                  )}?w=320&pad-top=20&fill=fill&fit=crop&fill-color=d9e2e6&h=180&blend-x=40&blend-fit=scale&bg=d9e2e6&trim=auto&crop=top&pad-left=90&pad-right=90&q=75`}
                  alt=""
                />
              </figure>
              <div className="item-card-02__main">
                <div className="item-card-02__tag">
                  {displayBooksArray.tag.map(({ tag, id }) => (
                    <UILabel key={id} text={tag} />
                  ))}
                </div>
                <h4 className="item-card-02__title">
                  {displayBooksArray.title}
                </h4>
                <p className="item-card-02__text">
                  {Util.concatTxt(
                    Util.deleteTag(displayBooksArray.content),
                    63,
                  )}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AwsRelation;
