/* eslint-disable no-underscore-dangle */
import { graphql, PageProps } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '@/components/seo';
import hoverCircle from '@/components/Interaction/hoverCircle';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import UIBox from '@/components/UI/UIBox';
import CDNReplace from '@/components/CDNReplace';
import ContactBox from '@/components/Contact/ContactBox';
import AwsRelation from '@/components/Service/AwsRelation';
import * as Util from '@/components/Util';
import { Context } from '@/components/Layout';

import * as titleLikeList from '@/styles/object/title-like-list.module.scss';
import * as pageHeaderSummary from '@/styles/object/page-header-summary.module.scss';
import * as textLead from '@/styles/object/text-lead.module.scss';
import * as pageHeader from '@/styles/object/page-header.module.scss';

const ServiceCategoryCloudSolution: React.FC<
  PageProps<GatsbyTypes.ServiceCategoryCloudSolutionQuery>
> = ({ data }) => {
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
  }, []);

  const ComprehensiveSupportArray: Pick<
    GatsbyTypes.MicrocmsService,
    | 'id'
    | 'serviceId'
    | 'title'
    | 'category'
    | 'label'
    | 'description'
    | 'displayDescription'
    | 'displayIndex'
  >[] = [];
  const IntroductionSupportArray: Pick<
    GatsbyTypes.MicrocmsService,
    | 'id'
    | 'serviceId'
    | 'title'
    | 'category'
    | 'label'
    | 'description'
    | 'displayDescription'
    | 'displayIndex'
  >[] = [];
  const SecurityArray: Pick<
    GatsbyTypes.MicrocmsService,
    | 'id'
    | 'serviceId'
    | 'title'
    | 'category'
    | 'label'
    | 'description'
    | 'displayDescription'
    | 'displayIndex'
  >[] = [];
  const OtherArray: Pick<
    GatsbyTypes.MicrocmsService,
    | 'id'
    | 'serviceId'
    | 'title'
    | 'category'
    | 'label'
    | 'description'
    | 'displayDescription'
    | 'displayIndex'
  >[] = [];

  data.allMicrocmsService.edges.forEach(({ node }) => {
    if (node.label[0] === `AWS/AWS総合支援`) {
      ComprehensiveSupportArray.push(node);
    } else if (node.label[0] === `AWS/導入支援・環境構築`) {
      IntroductionSupportArray.push(node);
    } else if (node.label[0] === `AWS/セキュリティ対策`) {
      SecurityArray.push(node);
    } else {
      OtherArray.push(node);
    }
  });

  let contactData: readonly Pick<
    MicrocmsServiceBodyContact,
    'button' | 'title' | 'id' | 'tayori'
  >[] = [];

  contactData = data.microcmsService.contact;

  return (
    !loading && (
      <>
        <SEO
          title={`${data.microcmsService.title} | サービス`}
          description={data.microcmsService.description}
        />
        <div className="page-wrap --cloud-solution">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeader.page_header}>
            <h1 className={pageHeader.page_header__title}>
              <small className={pageHeader.page_header__head_text}>
                {data.microcmsService.title}
              </small>
              <div className={pageHeader.page_header__text}>
                {data.microcmsService.subTitle}
              </div>
            </h1>
          </header>
          <div className="page-contents-box">
            <div className="section">
              <p
                className={textLead.text_lead}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    data.microcmsService.displayDescription !== `` ||
                    data.microcmsService.displayDescription !== undefined
                      ? data.microcmsService.displayDescription
                      : data.microcmsService.description,
                }}
              />
            </div>
            <div className="section">
              <section className="section-inner">
                <h3 className={titleLikeList.title_like_list}>AWS総合支援</h3>
                <div className="box-2rows">
                  {ComprehensiveSupportArray.map(
                    ({
                      serviceId,
                      category,
                      title,
                      description,
                      displayDescription,
                      displayIndex,
                    }) => (
                      <UIBox
                        key={serviceId}
                        title={title}
                        description={
                          displayDescription !== `` ||
                          displayDescription !== undefined
                            ? displayDescription
                            : description
                        }
                        link={`/service/${category}/${serviceId}/`}
                        displayIndex={displayIndex}
                      />
                    ),
                  )}
                </div>
              </section>
              <section className="section">
                <h3 className={titleLikeList.title_like_list}>
                  導入支援・環境構築
                </h3>
                <div className="box-2rows">
                  {IntroductionSupportArray.map(
                    ({
                      serviceId,
                      category,
                      title,
                      description,
                      displayDescription,
                      displayIndex,
                    }) => (
                      <UIBox
                        key={serviceId}
                        title={title}
                        description={
                          displayDescription !== `` ||
                          displayDescription !== undefined
                            ? displayDescription
                            : description
                        }
                        link={`/service/${category}/${serviceId}/`}
                        displayIndex={displayIndex}
                      />
                    ),
                  )}
                </div>
              </section>
              <section className="section">
                <h3 className={titleLikeList.title_like_list}>
                  セキュリティ対策
                </h3>
                <div className="box-2rows">
                  {SecurityArray.map(
                    ({
                      serviceId,
                      category,
                      title,
                      description,
                      displayDescription,
                      displayIndex,
                    }) => (
                      <UIBox
                        key={serviceId}
                        title={title}
                        description={
                          displayDescription !== `` ||
                          displayDescription !== undefined
                            ? displayDescription
                            : description
                        }
                        link={`/service/${category}/${serviceId}/`}
                        displayIndex={displayIndex}
                      />
                    ),
                  )}
                </div>
              </section>
              {/* TODO: 設定されてないものをその他にしているだけなので、削除予定 */}
              <section className="section">
                <h3 className={titleLikeList.title_like_list}>その他</h3>
                <div className="box-2rows">
                  {OtherArray.map(
                    ({
                      serviceId,
                      category,
                      title,
                      description,
                      displayDescription,
                      displayIndex,
                    }) => (
                      <UIBox
                        key={serviceId}
                        title={title}
                        description={
                          displayDescription !== `` ||
                          displayDescription !== undefined
                            ? displayDescription
                            : description
                        }
                        link={`/service/${category}/${serviceId}/`}
                        displayIndex={displayIndex}
                      />
                    ),
                  )}
                </div>
              </section>
              <div className="editor">
                {data.microcmsService.body &&
                  data.microcmsService.body.map((body) => {
                    let output = ``;
                    if (body.rich) {
                      output = body.rich;
                    }
                    if (body.plain) {
                      output = body.plain;
                    }
                    if (body.column) {
                      const arr = body.column;
                      arr.forEach((column) => {
                        let columnOutputInner = ``;
                        if (column.rich) {
                          columnOutputInner += column.rich;
                        }
                        if (column.plain) {
                          columnOutputInner += column.plain;
                        }
                        if (column.label) {
                          columnOutputInner += column.label;
                        }

                        output += `<div ${
                          column.class !== null
                            ? `class="${Util.commaShapeBlank(column.class)}"`
                            : ``
                        }>${
                          column.url
                            ? `<a href=${column.url}>${columnOutputInner}</a>`
                            : `${columnOutputInner}`
                        }</div>`;
                      });
                    }
                    if (body.asset) {
                      const assetArray = body.asset;
                      assetArray.forEach((Item) => {
                        const arr = Item.assetBody;
                        arr.forEach((elem) => {
                          if (elem.rich) {
                            output += elem.rich;
                          }
                          if (elem.plain) {
                            output += elem.plain;
                          }
                        });
                      });
                    }
                    if (body.contact) {
                      let contactBtn = ``;
                      body.contact.forEach((contactItem) => {
                        contactBtn += `<a href="/contact/${contactItem.id}/" class="button-02 js-hover-circle" data-circle-type="reverse-button" data-circle-inner="arrow-right-orange">${contactItem.button}</a>`;
                      });
                      output += `<div class="btn">${contactBtn}</div>`;
                    }
                    if (body.file) {
                      output += `<a href="${body.file.url}" target="_blank">${body.fileLabel}</a>`;
                    }
                    return (
                      <div
                        className={
                          body.class !== null
                            ? `${Util.commaShapeBlank(body.class)}`
                            : ``
                        }
                        id={
                          body.anchorId
                            ? `${body.anchorId.replace(`#`, ``)}`
                            : ``
                        }
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: CDNReplace(output) }}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <ContactBox service={contactData} />
        <AwsRelation />
        <UIBreadcrumb
          UpperItem={[[`Service`, `/service`]]}
          CurrentItem="Cloud Solution"
        />
      </>
    )
  );
};

export const query = graphql`
  query ServiceCategoryCloudSolution {
    allMicrocmsService(
      filter: {
        category: { in: "cloud-solution" }
        serviceId: { ne: "cloud-solution" }
      }
      sort: { fields: sort }
    ) {
      edges {
        node {
          id
          serviceId
          title
          category
          label
          description
          displayDescription
          displayIndex
        }
      }
    }
    microcmsService(serviceId: { eq: "cloud-solution" }) {
      id
      title
      subTitle
      description
      displayDescription
      body {
        anchorId
        class
        column {
          fieldId
          rich
          plain
        }
        asset {
          assetBody {
            plain
            rich
          }
        }
        contact {
          button
          display
          tayori
          title
          id
        }
        file {
          url
        }
        fileLabel
        rich
        plain
      }
      contact {
        button
        tayori
        title
        id
      }
    }
  }
`;

export default ServiceCategoryCloudSolution;
